import React from "react"

import { Layout } from "components/layout"
import { Meta } from "components/meta"
import { HeroMini } from "components/hero"
import { Section } from "components/section"
import ImpressumContent from "components/policies/ImpressumContent"

const heroProps = {
  title: <>Impressum</>,
}

const Impressum = (props) => (
  <Layout location={props.location}>
    <Meta title="Impressum" />
    <HeroMini {...heroProps} />
    <Section noTopSpacing>
      <ImpressumContent />
    </Section>
  </Layout>
)

export default Impressum
