import React from "react"
import s from "./Styles.module.scss"

const ImpressumContent = () => (
  <div className={s.wrapper}>
    <h4>Martian &amp; Machine GmbH</h4>
    <br />

    <p className={s.paragraph}>
      Karl-Liebknecht-Str. 29A
      <br />
      10178 Berlin
    </p>
    <br />

    <p className={s.paragraph}>
      Vertreten durch: Jerolim Dragojevic (Geschäftsführer)
      <br />
      E-Mail <a href="mailto:hello@martian.ventures">hello@martian.ventures</a>
    </p>
    <br />

    <p className={s.paragraph}>
      USt. IdNr. DE297283595
      <br />
      Registergericht Amtsgericht Charlottenburg
      <br />
      Registernummer HRB 162751
    </p>
  </div>
)

export default ImpressumContent
